/* AdminNav Styles */
.admin-nav {
  background-color: white;  /* Changed to white for better readability */
  padding: 20px;
  border-radius: 8px;  /* Optional: adds rounded corners for a cleaner look */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Optional: adds subtle shadow for depth */
}

.admin-nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.admin-nav ul li {
  margin-bottom: 20px;  /* Increased margin for better spacing */
}

/* Section Header Styles */
.nav-section {
  font-size: 1.4rem;
  color: #343a40;  /* Darker color for better contrast */
  margin: 20px 0 15px;  /* Increased margin for spacing between sections */
  border-bottom: 2px solid #6c757d;
  padding-bottom: 5px;
}

/* Style for Links in Dropdown */
.admin-nav ul li a {
  color: white;
  background-color: #007bff;
  padding: 12px 18px;  /* Increased padding for larger clickable area */
  border-radius: 5px;
  display: block;
  text-align: center;
  transition: background-color 0.3s ease;
}

/* Hover Effect for Links */
.admin-nav ul li a:hover {
  background-color: #0056b3;
}

/* Styling for the Content inside each dropdown section */
.admin-nav ul li > * {
  width: 100%;  /* Ensure that content takes full width of the parent container */
  padding: 15px;  /* Add padding to make components less cramped */
  margin-bottom: 15px;  /* Add space between components */
  background-color: #f8f9fa;  /* Light background color for components */
  border-radius: 5px;  /* Rounded corners for components */
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);  /* Optional: adds light shadow for depth */
}

/* Responsive Design */
@media (min-width: 768px) {
  .admin-nav ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .admin-nav ul li {
    width: 48%;  /* Make items more spacious */
    margin-bottom: 20px;
  }

  .admin-nav ul li a {
    padding: 15px;
  }

  .nav-section {
    width: 100%;
    margin-top: 20px;
  }
}

@media (max-width: 730px) {
  .admin-nav ul li {
    width: 100%;
  }
}

/* Mobile Design */
@media (max-width: 480px) {
  .admin-nav {
    padding: 10px;
  }

  .admin-nav ul li a {
    font-size: 0.9rem;
    padding: 12px;
  }

  .nav-section {
    font-size: 1.2rem;
  }
}
