/* Global Styles */
:root {
    --deep-forest-green: #2C3E50; /* Deep forest green */
    
    /* Color Palette from Coolors */
    --color-1: #6f1d1b; /* Dark Red */
    --color-2: #bb9457; /* Tan */
    --color-3: #614328; /* Brown */
    --color-4: #432818; /* Dark Brown */
    --color-5: #99582a; /* Light Brown */
    --color-6: #cc9f69; /* Light Tan */
    --color-7: #ffe6a7; /* Light Yellow */
  }
  
  /* Global Styles for Navbar */
  body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
  }
  
  /* Other global styles can go here */
  