.product-card {
  list-style: none;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 16px;
  max-width: 300px;
  position: relative;
  overflow: hidden; /* Prevent content overflow */
  transition: transform 0.3s ease, box-shadow 0.3s ease, height 0.3s ease;
  width: 70%;
}

.product-card:hover {
  transform: scale(1.05); /* Grow the card on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.product-content {
  position: relative;
}

.product-image {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  object-fit: contain; /* Ensure the image doesn't get cut off or distorted */
  border-radius: 4px;
  margin-bottom: 8px;
  transition: transform 0.3s ease;
}

.product-card:hover .product-image {
  transform: scale(1.05); /* Slightly scale image without overflowing */
}

.interested-button {
  background-color: #093112;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
  width: 75%;
  margin: 16px auto 0; /* Center horizontally with margin */
  display: block;
  text-align: center;
}

.product-card:hover .interested-button {
  opacity: 1; /* Show the button when the card is hovered */
}

.product-price {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
  color: #262626;
  font-weight: 500;
}

/* Mobile Styles */
@media (max-width: 730px) {
  .product-card {
    width: 100%; /* Take full width on mobile */
  }

  .product-image {
    max-height: 250px;
    object-fit: cover; /* Adapt fit style for mobile */
  }

  .interested-button {
    display: none;
  }
}
