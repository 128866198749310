

      /* Navbar Container */
      .navbar {
        background-color: #092f12;
        padding: 1rem 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        /* position: sticky; */
        /* top: 0; */
        /* z-index: 1000; */
        width: 100%;
      }

      /* Navbar Brand Section */
      .navbar-brand {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }

      /* Navbar Title */
      .navbar-brand h1 {
        color: wheat;
        margin: 0;
      }

      /* Hamburger Icon */
      .navbar-toggle {
        background: none;
        border: none;
        color: wheat;
        font-size: 2rem;
        cursor: pointer;
        display: none; /* Hidden by default */
      }

      /* Navbar List */
      .navbar-list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        gap: 20px;
        flex-direction: row;
        justify-content: center;
        transition: max-height 0.3s ease;
      }

      /* Navbar Items */
      .navbar-item {
        margin: 0;
      }

      /* Links Styling */
      .navbar-item a {
        text-decoration: none;
        color: white;
        font-size: 1.1rem;
        padding: 0.5rem 1rem;
        border-radius: 5px;
        transition: background-color 0.3s ease, color 0.3s ease;
      }

      /* Hover Effect for Links */
      .navbar-item a:hover {
        background-color: #575757;
        color: #f0f0f0;
      }

      /* Active Link Styling */
      .navbar-item a.active {
        background-color: #ff6f61;
        color: white;
      }

      .logo {
        display: flex;            /* Use flexbox to align items inline */
        align-items: center;     /* Center vertically */
      }

      .nav-logo {
        margin-right: 10px;      /* Add some space between the image and the heading */
        height: 10em;            /* Adjust the height as needed */
      }

      .toggle-button {
        background-color: #092f12; /* Replace with your desired color */
        color: white;                 /* Text color */
        border: none;                 /* Remove default border */
        padding: 10px 20px;          /* Adjust padding as needed */
        border-radius: 5px;          /* Rounded corners */
        cursor: pointer;              /* Change cursor to pointer */
        /* position: absolute;           Position it absolutely */
        right: 20px;                  /* Distance from the right edge */
        top: 10px;                    /* Distance from the top (adjust as needed) */
      }

      .toggle-button:hover {
        background-color: #092f12; /* Change this for hover effect */
      }



      /* Responsive Design */
      @media (max-width: 730px) {
        .navbar {
          padding: 1rem;
        }

        /* Show the hamburger menu button */
        .navbar-toggle {
          display: block; /* Hamburger icon appears on small screens */
          width: 35%;
          /* display: none; */
          color: wheat;
          background: transparent;
        }

        /* Navbar is Hidden by Default */
        .navbar-list {
          flex-direction: column;
          max-height: 0;
          overflow: hidden;
          width: 100%;
          transition: max-height 0.3s ease; /* Smooth transition */
        }

        /* When Menu is Open */
        .navbar-list.open {
          max-height: 500px; /* Adjust according to your content */
        }

        .navbar-item {
          text-align: center;
          width: 100%;
        }

        .navbar-item a {
          display: block;
          width: 100%;
          padding: 1rem 0;
        }

        /* Ensure Hover Styles Are Active */
        .navbar-item a:hover {
          background-color: #575757; /* Same hover style */
          color: #f0f0f0;
        }

        .navbar-item a.active {
          background-color: #ff6f61;
          color: white;
        }

        .nav-logo {
          width: 10em;
        }
      }
