.bigger-prod-card {
  display: flex;
  flex-direction: row; /* Side by side layout for larger screens */
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 20px auto;
  font-size: 1rem;
}

.bigger-image-container {
  flex: 1;
  max-width: 45%; /* Restrict image width */
  overflow: hidden;
  border-radius: 10px;
  margin-right: 20px; /* Space between image and details */
}

.product-image {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.bigger-product-details {
  flex: 1;
  color: #333;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px; /* Consistent spacing between text elements */
}

.bigger-product-name {
  font-size: 1.8rem;
  font-weight: bold;
}

.bigger-product-price {
  font-size: 1.4rem;
  font-weight: bold;
}

.bigger-product-disclaimer {
  font-size: 1.2rem;
  color: #1a38cb;
  background-color: #6868687b;
  font-weight: 600;
  padding: 5px;
  border-radius: 5px;
}

/* Responsive Design */
@media (max-width: 730px) {
  .bigger-prod-card {
    flex-direction: column; /* Stack items vertically */
    padding: 15px;
    font-size: 0.9rem;
  }

  .bigger-image-container {
    margin-right: 0; /* Remove spacing in stacked layout */
    max-width: 100%; /* Allow full width on small screens */
  }

  .bigger-product-name {
    font-size: 1.5rem;
  }

  .bigger-product-price {
    font-size: 1.2rem;
  }

  .bigger-product-disclaimer {
    font-size: 1rem;
  }
}
