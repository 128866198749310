/* PhotosDisplay.css */
/* General button styles */
/* General navigation button styling */
.image-gallery-icon {
  height: 2em; /* Set the height of the navigation buttons */
  background-color: transparent !important; /* Transparent background */
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px;
  font-size: 1.5rem; /* Larger font size for navigation text */
  font-weight: bold;
  color: #ffffff; /* Default text color */
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Hover effect for navigation buttons */
.image-gallery-icon:hover {
  background-color: rgba(0, 123, 255, 0.1); /* Light blue hover background */
  color: #0056b3; /* Darker blue hover color */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 730px) {
  .image-gallery-icon {
    font-size: 1.2rem; /* Slightly smaller font size for text */
  }
}



/* Ensure the gallery container stretches to fullscreen */
.gallery-container {
  position: relative;
  width: 80%;
  margin: 0 auto;
  transition: all 0.3s ease-in-out;
}

.gallery-container.fullscreen {
  width: 100%;
  height: 100%;
  position: fixed; /* Fill the screen */
  top: 0;
  left: 0;
  z-index: 1000; /* Ensure it's above all other content */
  background-color: black; /* Fullscreen background color */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Images in fullscreen mode */
.gallery-container.fullscreen .image-slide {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* Maintain aspect ratio */
  display: block;
}



/* Hover effect */
.image-gallery-icon:hover {
  background-color: #007bff !important; /* Blue background on hover */
  color: white; /* White icon color */
}

/* Specific styles for navigation buttons */
.image-gallery-left-nav,
.image-gallery-right-nav {
  background-color: #007bff !important; /* Orange background */
  max-width: 23%;
}

/* Specific styles for play button */
.image-gallery-play-button {
  background-color: #007bff !important; /* Green background */
  max-width: 23%;
  right: 33;
}

/* Specific styles for fullscreen button */
.image-gallery-fullscreen-button {
  background-color: #6c757d !important; /* Gray background */
  max-width: 23%;
  right: 33;
}

.image-gallery-thumbnail-image {
  object-fit: cover;
  max-height: 100px;
  
}

/* Container for the gallery */
.gallery-container {
  width: 80%;
  margin: 0 auto;
  position: relative;
}

/* Images inside the gallery */
.image-slide {
  max-height: 400px;
  object-fit: cover;
  display: block;
  margin: 0 auto;
}

/* Shared style for the left and right buttons */
.gallery-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  color: white;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Left button specific style */
.gallery-button-left {
  left: -40px;
}

/* Right button specific style */
.gallery-button-right {
  right: -40px;
}

/* Hover effect on buttons */
.gallery-button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.toggle-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.toggle-buttons button {
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  cursor: pointer;
}

.toggle-buttons .active {
  background-color: #4caf50;
  color: white;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 730px) {
  .gallery-container {
    width: 95%;
  }

  .toggle-buttons {
    flex-direction: column;
    align-items: center;
  }

  .toggle-buttons button {
    /* width: 100%; */
    max-width: 200px; /* Optional: Set a max width for buttons on smaller screens */
    margin: 0.25rem 0; /* Reduce margin for buttons on smaller screens */
    padding: 0.5rem; /* Adjust padding */
    font-size: 0.9rem; /* Slightly reduce font size */
  }

  .image-slide {
    max-height: 500px; /* Reduce image height on smaller screens */
  }

  /* Move gallery navigation buttons closer to the image */
  .gallery-button-left {
    left: -20px;
  }

  .gallery-button-right {
    right: -20px;
  }
}

/* Adjust navigation button positions and size for smaller screens */
@media (max-width: 780px) {
  /* Adjust the general size and padding for navigation buttons */
  .image-gallery-icon {
    max-width: 20%;
    max-height: 15%;
    padding: 5px;
  }

  /* Adjust specific styles for the right navigation button */
  .image-gallery-right-nav {
    right: 5px; /* Keep it closer to the edge without overlapping */
    max-width: 20%;
    max-height: 15%;
    padding: 5px;  
  }

  /* Adjust specific styles for the left navigation button */
  .image-gallery-left-nav {
    left: 5px; /* Keep it closer to the edge without overlapping */
    background-color: white;
    max-width: 20%;
    max-height: 15%;
    padding: 5px;
  }
  


  /* Optional: Adjust icon size within the button */
  .image-gallery-svg {
    width: 20px;
    height: 20px;
  }
}

