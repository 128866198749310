.admin-product-type-manager {
    margin: 20px;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .type-manager-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 20px;
  }
  
  .product-type-list, .add-product-type-form, .edit-product-type-form {
    flex: 1;
    min-width: 300px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .product-type-list {
    display: flex;
    flex-direction: column;
  }
  
  select {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    font-size: 16px;
  }
  
  .edit-product-type-form, .add-product-type-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  h3 {
    margin-bottom: 10px;
  }
  
  label {
    font-weight: bold;
  }
  
  input[type="text"], input[type="checkbox"] {
    padding: 10px;
    font-size: 16px;
  }
  
  input[type="checkbox"] {
    margin-right: 10px;
    width: 20px;
  }
  
  button {
    padding: 10px 20px;
    margin-top: 10px;
    font-size: 16px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
  }
  
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  button[type="button"] {
    background-color: #f44336;
  }
  
  button[type="button"]:hover {
    background-color: #d32f2f;
  }
  
  button[type="submit"]:hover {
    background-color: #0056b3;
  }
  
  .add-product-type-form form, .edit-product-type-form form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .clear-btn {
    background-color: #e0e0e0;
    border: 1px solid #ccc;
    color: black;
  }
  
  .clear-btn:hover {
    background-color: #bdbdbd;
  }
  