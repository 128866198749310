.home-container {
    text-align: center;
    font-family: "Arial", sans-serif;
    /* padding: 20px; */
  }
  
  .header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
  }
  
  .home-header {
    text-align: center;
    padding: 40px 20px;
    background-color: #092f12;
    width: 100%;
    height: auto;
  }

  .logo-img {
    width: 30em;
    /* height: 25em; */
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
  }
  
  
  .home-logo {
    width: 10em;
    height: auto;
    margin-bottom: 10px;
  }
  
  .home-title {
    font-size: 3em;
    color: wheat;
    font-weight: bold;
    margin: 0;
  }

  .home-subtitle {
    color: wheat;
    font-size: 1.75em;
    margin-top: 10px;
  }

  .title {
    font-size: 2.5em;
    color: wheat;
    font-weight: bold;
    margin: 0;
  }
  
  .home-subtitle {
    color: wheat;
    font-size: 1.2em;
    margin-top: 10px;
  }
  
  .shed-photo {
    width: 76%;
    max-height: 70vh;
    object-fit: cover;
    border: 8px solid white;
    border-radius: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
    display: block;
    margin: 20px auto;
  }
  
  .carousel-container {
    margin: 20px auto;
    max-width: 80%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
  }
  
  .carousel-item {
    display: flex;
    justify-content: space-between;
  }
  
  .carousel-link {
    text-decoration: none;
    width: 32%;
    position: relative;
  }
  
  .carousel-image {
    width: 100%;
    max-height: 70vh;
    object-fit: contain;
  }
  
  .carousel-caption {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    text-align: center;
    padding: 10px 0;
  }
  
  .toggle-button {
    padding: 10px;
    margin: 20px;
    font-size: 16px;
    cursor: pointer;
    max-width: 25%;
  }
  