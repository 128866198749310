.admin-prod-inquiries {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  box-sizing: border-box; /* Ensures padding doesn't cause overflow */
}

.content {
  display: flex;
  gap: 20px;
  flex-wrap: wrap; /* Ensures wrapping for smaller screens */
  justify-content: space-between;
  align-items: flex-start;
}

/* Table Section */
.inquiries-table {
  flex: 2;
  min-width: 300px; /* Ensure table fits on smaller screens */
  width: 100%; /* Adjust table to take full width */
  overflow-x: auto; /* Allow horizontal scrolling */
}

table {
  width: 100%;
  border-collapse: collapse;
  overflow-x: auto; /* Enable horizontal scrolling on smaller screens */
}

th, td {
  padding: 8px 12px;
  text-align: left;
  border: 1px solid #ddd;
  white-space: nowrap; /* Prevent table content from wrapping */
}

thead th {
  position: sticky;
  top: 0;
  background-color: #f4f4f4; /* Sticky header background */
  z-index: 1;
}

tr.selected {
  background-color: #d3e8ff;
}

/* Form Section */
.inquiry-form {
  flex: 1;
  min-width: 300px; /* Ensure form fits on smaller screens */
  max-width: 400px; /* Limit form width for readability */
  width: 100%; /* Adjust form to take full width on smaller screens */
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box; /* Prevent form from exceeding its container */
}

/* Inputs and Buttons */
input[type="text"], input[type="number"], select, textarea {
  width: 100%; /* Full width for form elements */
  padding: 10px;
  margin: 8px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

button {
  width: 100%; /* Full-width buttons for smaller screens */
  padding: 12px;
  margin-top: 10px;
  background-color: #0056b3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #00409d;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .content {
    flex-direction: column; /* Stack table and form vertically */
  }

  .inquiries-table, .inquiry-form {
    width: 100%; /* Full width for smaller screens */
  }

  th, td {
    font-size: 14px;
    padding: 6px 8px;
  }

  button {
    font-size: 14px;
    padding: 10px;
  }
}

@media (max-width: 480px) {
  th, td {
    font-size: 12px; /* Further reduce font size for very small screens */
    padding: 4px 6px;
  }

  input[type="text"], input[type="number"], select, textarea {
    font-size: 14px; /* Adjust input font size */
  }

  button {
    padding: 8px 12px;
    font-size: 12px;
  }

  .inquiries-table {
    overflow-x: scroll; /* Force scroll for very small screens */
  }
}
