.contact-form-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .contact-form-container h2 {
    margin-bottom: 15px;
  }
  
  .contact-form-container label {
    display: block;
    margin-bottom: 5px;
  }
  
  .contact-form-container input,
  .contact-form-container textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .contact-form-container textarea {
    height: 150px;
    resize: vertical;
  }
  
  .submit-button {
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit-button:disabled {
    background-color: #ccc;
  }
  
  .success-message {
    color: green;
    margin-top: 10px;
  }
  
  .error-message {
    color: red;
    margin-top: 10px;
  }
  