/* Import Mulish font */
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400&display=swap');

/* General Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Mulish', sans-serif;
  background-color: #f4f4f4;
  color: #333;
  line-height: 1.6;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

/* Layout */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

/* Header */
header {
  background-color: #333;
  color: rgb(0, 0, 0);
  padding: 10px 0;
  text-align: center;
}

header h1 {
  font-size: 2rem;
}

/* Navigation */
nav ul {
  list-style-type: none;
}

nav ul li {
  display: inline-block;
  margin: 0 10px;
}

nav ul li a {
  color: rgb(0, 0, 0);
  padding: 10px 15px;
  border-radius: 5px;
}

nav ul li a:hover {
  background-color: #7e7e7e;
}

/* Button */
button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

/* Cards */
.card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  padding: 20px;
}

.card img {
  border-radius: 8px;
}

.card h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.card p {
  font-size: 1rem;
  color: #666;
}

/* Footer */
footer {
  background-color: #333;
  color: white;
  padding: 20px;
  text-align: center;
  margin-top: 30px;
}

/* Media Queries for Responsiveness */
@media (max-width: 730px) {
  .flex {
    flex-direction: column;
  }

  nav ul li {
    display: block;
    text-align: center;
  }

  header h1 {
    font-size: 1.5rem;
  }

  button {
    /* width: 100%; */
    padding: 15px;
  }
}

@media (max-width: 480px) {
  header h1 {
    font-size: 1.2rem;
  }

  .card h2 {
    font-size: 1.2rem;
  }
}
