.display-manager-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .form-and-list {
    display: flex;
    justify-content: space-between;
    width: 80%;
  }
  
  .add-display-form {
    flex: 1;
    margin-right: 20px;
  }
  
  .display-list {
    flex: 2;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  table th, table td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  table tr:hover {
    background-color: #f5f5f5;
  }
  
  button {
    margin-top: 10px;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .success-message {
    color: green;
  }
  
  .error-message {
    color: red;
  }
  