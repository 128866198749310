.product-gallery {
  display: flex;
  flex-wrap: wrap; /* Enables wrapping into new rows */
  justify-content: center; /* Centers the items horizontally */
  align-items: flex-start; /* Aligns items at the top */
  padding: 0;
  list-style: none;
  gap: 20px; /* Adds spacing between product cards */
  width: 100%;
}

.product-card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  max-width: 300px; /* Max width of each card */
  box-sizing: border-box;
  transition: box-shadow 0.3s ease;
  flex: 1 1 calc(33.333% - 40px); /* Three columns */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensure uniform spacing */
  min-height: 400px; /* Ensures cards have a minimum height */
}

.product-description {
  font-size: 1rem;
  margin-bottom: 10px;
  color: #666;
  overflow: hidden; /* Prevent overflow */
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.product-image {
  width: 100%;
  height: 200px; /* Fixed height for images */
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 10px;
}

.product-list-title {
  /* center this */
  text-align: center;
  margin-top: 10px;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}

@media (min-width: 1024px) {
  .product-card {
    flex: 1 1 calc(33.333% - 40px);
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .product-card {
    flex: 1 1 calc(50% - 40px);
  }
}

@media (max-width: 730px) {
  .product-gallery {
    gap: 10px; /* Reduced gap for medium-small screens */
  }

  .product-card {
    flex: 1 1 calc(50% - 10px); /* Two columns on smaller screens */
  }

  .filter-items {
    display: flex;
    flex-direction: row; /* Align items horizontally for small screens */
    overflow-x: auto; /* Enable horizontal scrolling */
    overflow-y: hidden; /* Disable vertical scrolling */
    white-space: nowrap; /* Keep items in a single line */
    scrollbar-width: thin; /* Thinner scrollbar */
    scrollbar-color: #5a5a5a #f0f0f0; /* Custom scrollbar colors */
    padding: 10px 0; /* Padding around the scrollbar */
  }
}

@media (max-width: 420px) {
  .product-gallery {
    gap: 5px; /* Further reduced gap for very small screens */
  }

  .product-card {
    flex: 1 1 calc(50% - 5px); /* Two columns on very small screens */
  }

  .filter-items {
    display: flex;
    flex-direction: row; /* Align items horizontally for very small screens */
    overflow-x: auto; /* Enable horizontal scrolling */
    overflow-y: hidden; /* Disable vertical scrolling */
    white-space: nowrap; /* Keep items in a single line */
    scrollbar-width: thin; /* Thinner scrollbar */
    scrollbar-color: #5a5a5a #f0f0f0; /* Custom scrollbar colors */
    padding: 10px 0; /* Padding around the scrollbar */
  }
}
.filters-container {
  margin-bottom: 20px;
}

.filter-items {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping to new rows */
  gap: 10px; /* Add spacing between items */
  justify-content: center; /* Center items */
}

.filter-item {
  padding: 10px 20px;
  margin: 5px;
  background-color: #5a5a5a;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  transition: background-color 0.3s ease;
}

.filter-item.active-filter {
  background-color: #093112;
}

.filter-item:hover {
  background-color: #d3d3d3;
  color: #000;
}

.filter-dropdown-container {
  display: none; /* Hidden by default */
  position: relative;
}

.filter-dropdown {
  display: block;
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #093112;
  color: #ddd;
  font-size: 1rem;
  cursor: pointer;
  appearance: none; /* Remove default dropdown styles */
  text-align: center;
  transition: box-shadow 0.3s ease;
}

.filter-dropdown:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.filter-dropdown:focus {
  outline: none;
  box-shadow: 0 0 0 2px #093112;
}

.filter-dropdown-arrow {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 1.2rem;
  color: #666;
}

@media (max-width: 700px) {
  .filter-items {
    display: none; /* Hide filter buttons on small screens */
  }

  .filter-dropdown-container {
    display: block; /* Show dropdown on small screens */
  }
}
