.admin-general-inquiries {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
  }
  
  .content {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .inquiries-table {
    flex: 2;
    width: 100%;
    overflow-x: auto;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    padding: 8px 12px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  thead th {
    background-color: #f4f4f4;
  }
  
  tr.selected {
    background-color: #d3e8ff;
  }
  
  .inquiry-form {
    flex: 1;
    max-width: 400px;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  textarea, select, input {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  button {
    width: 100%;
    margin-top: 10px;
    padding: 10px;
    background-color: #0056b3;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #00409d;
  }
  
  @media (max-width: 768px) {
    .content {
      flex-direction: column;
    }
  }
  