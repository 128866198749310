.admin-product-type-add {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .admin-product-type-add h2 {
    margin-bottom: 20px;
  }
  
  .admin-product-type-add form {
    display: flex;
    flex-direction: column;
  }
  
  .admin-product-type-add label {
    margin-bottom: 5px;
  }
  
  .admin-product-type-add input[type="text"] {
    margin-bottom: 15px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .admin-product-type-add input[type="checkbox"] {
    margin-left: 10px;
  }
  
  .admin-product-type-add button {
    padding: 10px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .admin-product-type-add button:disabled {
    background-color: #ccc;
  }
  